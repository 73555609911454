<template>
  <page
    :title="role"
    :subtitle="companyName"
    :imageUrl="imageUrl"
    :apiStatusList="[fetchJobViewStatus]"
  >
    <template v-slot:subtitle v-if="true">
      <v-row class="pl-5">
        <v-col>
          {{ companyName }}
        </v-col>
      </v-row>
    </template>

    <v-card v-if="!loading" class="pl-16 py-6" elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <div class="text-h6 font-weight-bold black--text pt-5">
              Description
            </div>
            <div>
              <html-view :html="currentJobData?.getAbout()"></html-view>
            </div>
            <div class="text-h6 font-weight-bold black--text pt-5">Skills</div>
            <div>
              <v-chip-group column>
                <v-chip
                  v-for="(skill, idx) in currentJobData?.getSkillsList()"
                  :key="idx"
                  color="success"
                >
                  {{ skill }}
                </v-chip>
              </v-chip-group>
            </div>
            <div class="text-h6 font-weight-bold black--text pt-5">
              Other Benefits
            </div>
            <ul>
              <li
                v-for="benefit in jobCtc?.getOtherBenefitsList()"
                :key="benefit"
              >
                {{ benefit }}
              </li>
            </ul>
          </v-col>
          <v-col cols="3">
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiSeat }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobTypePretty(this.jobTypes[jobMetaData?.getJobType()]) }}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiBriefcase }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobPref?.join(', ') }}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiMapMarker }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobMetaData?.getLocationList()?.join(', ') }}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiFormatListNumbered }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ expr }}
              </span>
            </div>
            <div class="pb-5" v-if="salaryIsZero">
              <span>
                <v-icon color="black">{{ currency }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                Best in industry
              </span>
            </div>
            <div v-else class="pb-5">
              <span>
                <v-icon color="black">{{ currency }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ salary }}
              </span>
            </div>
            <div class="pb-5">
              <span class="text-body-1 font-weight-medium">
                Equity {{ equity }}
              </span>
            </div>
            <v-dialog v-model="confirmDialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline"
                    >Confirm your experience and skills</span
                  >
                </v-card-title>
                <v-card-text>
                  <div>
                    <h3>Experience:</h3>
                    <ul>
                      <li>{{ expr }}</li>
                    </ul>
                  </div>
                  <div>
                    <h3>Skills:</h3>
                    <ul>
                      <li
                        v-for="(skill, idx) in currentJobData?.getSkillsList()"
                        :key="idx"
                      >
                        {{ skill }}
                      </li>
                    </ul>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="cancel"
                    >Cancel</v-btn
                  >
                  <v-btn color="green darken-1" text @click="submit"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- file upload code starts here -->
            <v-card>
              <v-card-text>
                <div>
                  <v-file-input
                    :rules="rules"
                    accept=".pdf"
                    label="Attach resume PDF file"
                    hint="Resume PDF"
                    persistent-hint
                    outlined
                    prepend-icon=""
                    :prepend-inner-icon="mdiAttachment"
                    v-model="resumePdf"
                  >
                  </v-file-input>
                  <!-- file upload ends here	 -->
                  <v-btn
                    color="success"
                    elevation="0"
                    class="alreadyAppliedToJob ? text-none text-body-1 green--text: text-none text-body-1"
                    :class="{ 'disable-events': alreadyAppliedToJob }"
                    @click="showConfirmDialog"
                    :loading="applying"
                    :outlined="alreadyAppliedToJob"
                  >
                    {{
                      alreadyAppliedToJob
                        ? 'You have already applied to this job'
                        : 'Apply Now'
                    }}
                  </v-btn>
                  <div>**Resume is required</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </page>
</template>
<script>
import Loader from '@/components/Loader.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import HtmlView from '@/components/HtmlView.vue'
import Page from '../Page'

import {
  mdiFormatListNumbered,
  mdiDotsHorizontal,
  mdiCurrencyInr,
  mdiCurrencyUsd,
  mdiCurrencyGbp,
  mdiCurrencyEur,
  mdiMapMarker,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiBriefcase,
  mdiWallet,
  mdiClose,
  mdiSeat,
  mdiAttachment,
} from '@mdi/js'
export default {
  props: {
    jobUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) => !!v || 'File is required',
      (v) => (v && v.type === 'application/pdf') || 'File must be a PDF',
      (v) => (v && v.size > 0) || 'File must be non-empty',
      (v) => (v && v.size < 10000000) || 'File must be smaller than 10MB',
    ],
    confirmDialog: false,
    resumePdf: null,
    mdiMapMarker,
    mdiBookmark,
    mdiBookmarkOutline,
    mdiBriefcase,
    mdiSeat,
    mdiDotsHorizontal,
    mdiCurrencyInr,
    mdiWallet,
    mdiFormatListNumbered,
    mdiClose,
    mdiAttachment,
    applying: false,
    alreadyAppliedToJob: undefined,
    loading: false,
    paymentFrequencies: [
      {
        name: 'per annum',
        value: 'PAYMENT_FREQUENCY_UNKNOWN',
      },
      {
        name: 'per month',
        value: 'PAYMENT_PER_MONTH',
      },
      {
        name: 'per annum',
        value: 'PAYMENT_PER_ANNUM',
      },
      {
        name: 'per project',
        value: 'PAYMENT_PER_PROJECT',
      },
    ],
  }),
  computed: {
    ...mapState('candidate', [
      'currentJob',
      'resumeResponse',
      'savedJobs',
      'fetchJobViewStatus',
    ]),
    ...mapState('user', ['user']),
    ...mapGetters('candidate', ['jobTypes', 'jobPrefs']),
    currentJobData() {
      console.log('Job View modal loading')
      return this.currentJob?.getDataView()?.getJobView()
    },
    jobMetaData() {
      return this.currentJob?.getMetaData()?.getJobMeta()
    },
    jobCtc() {
      return this.jobMetaData?.getCtc()
    },
    jobPref() {
      return this.jobMetaData?.getPreferenceList().map((v) => this.jobPrefs[v])
    },
    role() {
      return this.currentJob?.getDataView()?.getJobView()?.getRole() || ''
    },
    companyName() {
      return this.currentJob?.getMetaData()?.getJobMeta().getCompanyName()
    },
    imageUrl() {
      if (this.currentJob?.getMetaData()?.getJobMeta()?.getCompanyLogoUrl()) {
        return this.currentJob?.getMetaData()?.getJobMeta()?.getCompanyLogoUrl()
      }
    },
    salaryIsZero() {
      console.log('CTC ..', this.jobMetaData?.getCtc().toObject())
      return (
        this.jobMetaData?.getCtc().getMaxCtc() === 0 &&
        this.jobMetaData?.getCtc().getMinCtc() === 0
      )
    },
    currency() {
      if (this.jobMetaData?.getCtc().getCurrency() === 'INR')
        return mdiCurrencyInr
      else if (this.jobMetaData?.getCtc().getCurrency() === 'USD')
        return mdiCurrencyUsd
      else if (this.jobMetaData?.getCtc().getCurrency() === 'GBP')
        return mdiCurrencyGbp
      else if (this.jobMetaData?.getCtc().getCurrency() === 'EURO')
        return mdiCurrencyEur
    },
    salary() {
      if (
        this.jobMetaData?.getCtc()?.getMinCtc() ===
        this.jobMetaData?.getCtc()?.getMaxCtc()
      ) {
        return (
          this.jobMetaData?.getCtc()?.getMinCtc()?.toLocaleString() +
          ' ' +
          this.paymentFrequencies[
            this.jobMetaData?.getCtc()?.getPaymentFrequency()
          ]?.name
        )
      } else {
        return (
          this.jobMetaData?.getCtc()?.getMinCtc()?.toLocaleString() +
          ' - ' +
          this.jobMetaData?.getCtc()?.getMaxCtc().toLocaleString() +
          ' ' +
          this.paymentFrequencies[this.jobCtc?.getPaymentFrequency()]?.name
        )
      }
    },
    expr() {
      if (
        this.jobMetaData?.getMinExperience() !==
        this.jobMetaData?.getMaxExperience()
      ) {
        return (
          this.jobMetaData?.getMinExperience() +
          ' - ' +
          this.jobMetaData?.getMaxExperience() +
          ' ' +
          'years'
        )
      } else {
        return this.jobMetaData?.getMinExperience() + '  ' + 'years'
      }
    },
    equity() {
      if (this.jobCtc?.getMinEquity() === this.jobCtc?.getMaxEquity()) {
        return this.jobCtc?.getMinEquity()
      } else if (this.jobCtc?.getMinEquity() > this.jobCtc?.getMaxEquity()) {
        return 'Negotiable'
      } else {
        return (
          this.jobCtc?.getMinEquity()?.toLocaleString() +
          '% -' +
          this.jobCtc?.getMaxEquity().toLocaleString() +
          '%'
        )
      }
    },
  },
  watch: {},
  methods: {
    ...mapActions('candidate', [
      'fetchJobView',
      'checkJobApplied',
      'applyToJob',
      'fetchResume',
      'saveJob', //TODO: add option from this page to save job
    ]),
    showConfirmDialog() {
      if (!this.resumePdf) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Please upload a resume!',
          type: 'error',
        })
        return
      }
      if (this.resumePdf.size > 10000000) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'File size must be < 10MB',
          type: 'error',
        })
        return
      }
      this.confirmDialog = true
    },
    cancel() {
      this.confirmDialog = false
    },
    submit() {
      this.confirmDialog = false
      this.applyToJobListing()
    },

    jobTypePretty(job) {
      console.log('Job is ..', job)
      if (job === 'FULL_TIME') return 'Full Time'
      if (job === 'INTERNSHIP') return 'Internship'
      if (job === 'CONTRACT') return 'Contract'
      else return 'NA'
    },
    paymentFrequencyPretty(frequency) {
      if (frequency === 'PAYMENT_PER_MONTH') return 'per month'
      if (frequency === 'PAYMENT_PER_ANNUM') return 'per annum'
      if (frequency === 'PAYMENT_PER_PROJECT') return 'per project'
      else return 'per annum'
    },
    fetchViewingJob(url) {
      console.log(url)
      this.loading = true
      this.fetchJobView({ url }).then(() => {
        if (this.user) {
          var id = this.currentJob.getId()
          console.log('JobId is ..', id)
          this.checkJobApplied({ id }).then((res) => {
            this.alreadyAppliedToJob = res?.getApplied()
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
    async applyToJobListing() {
      console.log('Applying ..', this.resumePdf)
      this.applying = true
      if (!this.user) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Login before applying to job',
          type: 'error',
        })
        this.applying = false
        return
      }
      if (!this.resumePdf) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Please upload resume',
          type: 'error',
        })
        this.applying = false
        return
      }
      if (this.resumePdf?.type !== 'application/pdf') {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Resume must be a PDF file',
          type: 'error',
        })
        this.applying = false
        return
      }
      if (this.resumePdf.size > 10000000) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'File size must be < 10MB',
          type: 'error',
        })
        this.applying = false
        return
      }
      await this.resumePdf
        .arrayBuffer()
        .then((content) => {
          this.applyToJob({
            id: this.currentJob.getId(),
            resume: new Uint8Array(content),
            name: this.resumePdf.name,
            type: this.resumePdf.type,
          }).then(() => {
            this.$store.dispatch('notifs/addNotif', {
              text: 'Successfully applied to job!',
              type: 'success',
            })
            this.applying = false
            this.alreadyAppliedToJob = true
          })
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: 'applyToJob: Error applying to job! Please try again later!',
            type: 'error',
          })
          this.applying = false
        })
    },
  },
  components: {
    Page,
    Loader,
    HtmlView,
  },
  mounted() {
    this.fetchViewingJob(this.jobUrl)
    console.log('This is jobview')
  },
}
</script>
<style>
.disable-events {
  pointer-events: none;
}
</style>

var render = function render(){
  var _vm$currentJobData, _vm$currentJobData2, _vm$jobCtc, _vm$jobMetaData, _vm$jobPref, _vm$jobMetaData2, _vm$jobMetaData2$getL, _vm$currentJobData3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.role,
      "subtitle": _vm.companyName,
      "imageUrl": _vm.imageUrl,
      "apiStatusList": [_vm.fetchJobViewStatus]
    },
    scopedSlots: _vm._u([true ? {
      key: "subtitle",
      fn: function () {
        return [_c('v-row', {
          staticClass: "pl-5"
        }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.companyName) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [!_vm.loading ? _c('v-card', {
    staticClass: "pl-16 py-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v(" Description ")]), _c('div', [_c('html-view', {
    attrs: {
      "html": (_vm$currentJobData = _vm.currentJobData) === null || _vm$currentJobData === void 0 ? void 0 : _vm$currentJobData.getAbout()
    }
  })], 1), _c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v("Skills")]), _c('div', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l((_vm$currentJobData2 = _vm.currentJobData) === null || _vm$currentJobData2 === void 0 ? void 0 : _vm$currentJobData2.getSkillsList(), function (skill, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success"
      }
    }, [_vm._v(" " + _vm._s(skill) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v(" Other Benefits ")]), _c('ul', _vm._l((_vm$jobCtc = _vm.jobCtc) === null || _vm$jobCtc === void 0 ? void 0 : _vm$jobCtc.getOtherBenefitsList(), function (benefit) {
    return _c('li', {
      key: benefit
    }, [_vm._v(" " + _vm._s(benefit) + " ")]);
  }), 0)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiSeat))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.jobTypePretty(this.jobTypes[(_vm$jobMetaData = _vm.jobMetaData) === null || _vm$jobMetaData === void 0 ? void 0 : _vm$jobMetaData.getJobType()])) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiBriefcase))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$jobPref = _vm.jobPref) === null || _vm$jobPref === void 0 ? void 0 : _vm$jobPref.join(', ')) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiMapMarker))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData2 = _vm.jobMetaData) === null || _vm$jobMetaData2 === void 0 ? void 0 : (_vm$jobMetaData2$getL = _vm$jobMetaData2.getLocationList()) === null || _vm$jobMetaData2$getL === void 0 ? void 0 : _vm$jobMetaData2$getL.join(', ')) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiFormatListNumbered))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.expr) + " ")])]), _vm.salaryIsZero ? _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.currency))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" Best in industry ")])]) : _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.currency))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.salary) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" Equity " + _vm._s(_vm.equity) + " ")])]), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.confirmDialog,
      callback: function ($$v) {
        _vm.confirmDialog = $$v;
      },
      expression: "confirmDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Confirm your experience and skills")])]), _c('v-card-text', [_c('div', [_c('h3', [_vm._v("Experience:")]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.expr))])])]), _c('div', [_c('h3', [_vm._v("Skills:")]), _c('ul', _vm._l((_vm$currentJobData3 = _vm.currentJobData) === null || _vm$currentJobData3 === void 0 ? void 0 : _vm$currentJobData3.getSkillsList(), function (skill, idx) {
    return _c('li', {
      key: idx
    }, [_vm._v(" " + _vm._s(skill) + " ")]);
  }), 0)])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Confirm")])], 1)], 1)], 1), _c('v-card', [_c('v-card-text', [_c('div', [_c('v-file-input', {
    attrs: {
      "rules": _vm.rules,
      "accept": ".pdf",
      "label": "Attach resume PDF file",
      "hint": "Resume PDF",
      "persistent-hint": "",
      "outlined": "",
      "prepend-icon": "",
      "prepend-inner-icon": _vm.mdiAttachment
    },
    model: {
      value: _vm.resumePdf,
      callback: function ($$v) {
        _vm.resumePdf = $$v;
      },
      expression: "resumePdf"
    }
  }), _c('v-btn', {
    staticClass: "alreadyAppliedToJob ? text-none text-body-1 green--text: text-none text-body-1",
    class: {
      'disable-events': _vm.alreadyAppliedToJob
    },
    attrs: {
      "color": "success",
      "elevation": "0",
      "loading": _vm.applying,
      "outlined": _vm.alreadyAppliedToJob
    },
    on: {
      "click": _vm.showConfirmDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.alreadyAppliedToJob ? 'You have already applied to this job' : 'Apply Now') + " ")]), _c('div', [_vm._v("**Resume is required")])], 1)])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }